<template>
	<div>
	<ContentHeader title="Data Pasien" />
    <div class="content-header">
		<div class="container-fluid">
			<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Pasien</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row" v-if="toogleCabang">
              <div class="col-lg-4">
                <label>Pilih Cabang</label>
                <multiselect 
                  v-model="cabang_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama"
                  label="nama"
                  placeholder=""
                  :options="cabang"
                  :loading="loading_cabang"
                  :allow-empty="false"
                  @update:model-value="getDataResult"
                >
                </multiselect>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-2">
                <span>Jumlah Baris</span>
                <select class="custom-select form-control" v-model="page" @change="getDataResult">
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                </select>
              </div>
              <div class="col-lg-6"></div>
              <div class="col-lg-4">
                <span>Cari Data</span>
                <input type="text" class="form-control" v-model="kata_kunci" @keyup="getDataResult">
              </div>
            </div>
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col" class="text-sm">REKAM MEDIS</th>
                  <th scope="col" class="text-sm">NAMA</th>
                  <th scope="col" class="text-sm">NO. ID</th>
                  <th scope="col" class="text-sm">ALAMAT</th>
                  <th scope="col" class="text-sm">NO. WHATSAPP</th>
                  <th scope="col" class="text-sm">GENDER</th>
                  <th scope="col" class="text-sm">AGAMA</th>
                  <th scope="col" class="text-sm">PEKERJAAN</th>
                  <th scope="col" class="text-sm" v-if="false">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in data_result" :key="row.id">
                  <td class="text-sm" scope="row">{{ row.nomor_rekam_medis }}</td>
                  <td class="text-sm">{{ row.nama }}</td>
                  <td class="text-sm">{{ row.nomor_identitas }}</td>
                  <td class="text-sm">{{ row.alamat_lengkap }}</td>
                  <td class="text-sm">{{ row.nomor_whatsapp }}</td>
                  <td class="text-sm">{{ row.jenis_kelamin }}</td>
                  <td class="text-sm">{{ row.agama }}</td>
                  <td class="text-sm">{{ row.pekerjaan }}</td>
                  <td class="text-sm" v-if="false">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-info text-sm">Proses</button>
                      <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                        <span class="sr-only"></span>
                      </button>
                      <div class="dropdown-menu" role="menu" style="">
                        <router-link :to="`/edit-data-pasien/${row.id}`" class="dropdown-item text-sm">Edit</router-link>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item text-sm" @click="tambahPasienKeAntrian(row)">Tambah Antrian</button>
                        <div class="dropdown-divider" v-if="level_jabatan != 'staff'"></div>
                        <button @click="konfirmasiHapus(row.id)" class="dropdown-item text-danger" v-if="level_jabatan != 'staff'">Hapus</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <nav aria-label="...">
              <ul class="pagination">
                <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
                  <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                </li>
                <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
                  <a class="page-link" href="#">Next</a>
                </li>
              </ul>
            </nav>
          </div>
          <!-- /.card-footer-->
        </div>

        <el-dialog v-model="dialogTambahAntrian" :title="`Tambah Antrian Pasien - ${pasien_selected?.nama} - ${pasien_selected?.nomor_rekam_medis}`" width="60%">
            <el-form ref="formRefKunjungan" :model="kunjunganBaru" label-position="left" label-width="200px">
                <el-form-item prop="dokter_id" label="Dokter" :rules="[ { required: true, message: 'Silahkan pilih dokter' } ]">
                  <el-select style="width:100%;" v-model="kunjunganBaru.dokter_id" @focus="dokter == null ? getDokter() : ''" :loading="loading_dokter" filterable placeholder="Pilih Dokter">
                    <el-option v-for="(item, index) in dokter" :key="index" :label="item.nama_lengkap" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="poli_id" label="Poli" :rules="[ { required: true, message: 'Silahkan pilih poli' } ]">
                  <el-select style="width:100%;" v-model="kunjunganBaru.poli_id" @focus="poli == null ? getPoli() : ''" :loading="loading_poli" filterable placeholder="Pilih Poli">
                    <el-option v-for="(item, index) in poli" :key="index" :label="item.nama" :value="item.poli_id" ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="layanan_id" label="Layanan" :rules="[ { required: true, message: 'Silahkan pilih layanan' } ]">
                  <el-select style="width:100%;" v-model="kunjunganBaru.layanan_id" @focus="layanan == null ? getLayanan() : ''" :loading="loading_layanan" filterable placeholder="Pilih layanan">
                    <el-option v-for="(item, index) in layanan" :key="index" :label="item.nama" :value="item.id" > </el-option>
                  </el-select>
                </el-form-item>
                
                <div class="d-flex justify-content-end">    
                    <el-button @click="dialogTambahAntrian = false">Batal</el-button>
                    <el-button type="primary" @click="submitTambahAntrian" :loading="loadingSubmit">Simpan</el-button>
                </div>
            </el-form>
        </el-dialog>

      </div>
    </div>
  </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted, reactive } from 'vue'
import store from '@/store'
import axios from 'axios'
// import Swal from 'sweetalert2'

const user = computed(() => store.getters['auth/user'])
const level_jabatan = computed(() => store.getters['auth/level_jabatan'])
const data_result = ref([])
const cek_data = ref(false)
const page = ref(10)
const links = ref([])
const pagination = ref([])
const memuat_data = ref(false)
const kata_kunci = ref('')

const getDataResult = async () => {
  data_result.value = []
  memuat_data.value = true
  cek_data.value = false

  let parameter = ''
  if (kata_kunci.value != '') {
    parameter = kata_kunci.value
  } else {
    parameter = 'emptyParameter'
  }

  let cabang_id = ''

  if (cabang_select_value.value == '') {
    cabang_id = user.value.cabang_id
  } else {
    cabang_id = cabang_select_value.value.id
  }

  let { data } = await axios.get(`api/pasien_kecantikan/get/${cabang_id}/${page.value}/${parameter}`)

  if (data != 'kosong') {
    memuat_data.value = false
    cek_data.value = false
    data_result.value = data.data
    links.value = data.links
      links.value.splice(0, 1)
      links.value.splice(links.value.length-1, 1)
      pagination.value = data
  } else {
    cek_data.value = true
    memuat_data.value = false
    data_result.value = []
  }
}

const paginateData = async (url) => {

    if (url != null) {
      data_result.value = []
      memuat_data.value = true

      let link_url = new URL(url)
      link_url = `${link_url.pathname}${link_url.search}`
      
      let { data } = await axios.get(`${link_url}`)

      
      if (data != 'kosong') {
        memuat_data.value = false
        data_result.value = data.data 
        links.value = data.links
        links.value.splice(0, 1)
        links.value.splice(links.value.length-1, 1)
        pagination.value = data
      }
    }
  }

const toogleCabang = ref(false)
const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
  let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

  if (response.data.jenis == 'pusat') {
    toogleCabang.value = true
    let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
    
    if (data != 'kosong') {
      cabang.value = data
      loading_cabang.value = false
    } else {
      cabang.value = []
      loading_cabang.value = false
    }
  }
}

// Tambah Antrian
const pasien_selected = ref(null)
const tambahPasienKeAntrian = (pasien) => {
  pasien_selected.value     = pasien
  kunjunganBaru.pasien_id   = pasien.id
  dialogTambahAntrian.value = true
}

const dialogTambahAntrian = ref(false)
const formRefKunjungan = ref()
const kunjunganBaru = reactive({
  'cabang_id' 	: user.value.cabang_id,
  'nomor'			  : null,
  'pasien_id' 	: null,
  'nama_pasien' : null,
  'poli_id'		  : null,
  'layanan_id'	: null,
  'status' 		  : 'dalam antrian',
  'jenis'			  : 'resepsionis'
})

onMounted(() => {
  getCabang()
  getDataResult()
})
</script>